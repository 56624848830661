import { Injectable } from '@angular/core';
import { Image, Message, Postal } from '../models/domain';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  private employeeImageSubject = new BehaviorSubject<string | null>(null);
  private companyImageSubject = new BehaviorSubject<string | null>(null);

  public employeeImage$ = this.employeeImageSubject.asObservable();
  public companyImage$ = this.companyImageSubject.asObservable();

  constructor(
    private httpClient: HttpClient
  ) { }

  setEmployeeImage(newImage: string) {
    this.employeeImageSubject.next(newImage);
  }

  setCompanyImage(newImage: string) {
    this.companyImageSubject.next(newImage); 
  }

  //POSTAL APIs
  public getPostalData(pincode: string): Observable<Postal[]> {
    return this.httpClient.get<Postal[]>(`https://api.postalpincode.in/pincode/${pincode}`);
  }

  public createImage(image:Image): Observable<string> {
    return this.httpClient.post<string>(`/api/image/v1`,image);
  }

  public getImage(type:string, refType: string,sid: number, label: string): Observable<Message> {
    return this.httpClient.get<Message>(`/api/image/v1/type/${refType}/sid/${sid}`, { params: {...(label && {label})}});
  }

}
