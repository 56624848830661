import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Config } from 'src/app/shared/models/config';
import { AuthRequest, AuthResponse, AuthService } from 'src/app/shared/services/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  @ViewChild('usernameInput', { static: true }) usernameInput!: ElementRef;
  public loginForm: FormGroup;
  public type: string;
  public toggle: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private commonService: CommonService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.usernameInput.nativeElement.focus();
    const isRemembered = this.commonService.getLocalItem(Config.rememberMe);
    if (isRemembered) {
      const credential = { username: isRemembered.username, password: this.commonService.decrypt(isRemembered.password), rememberMe: true } as AuthRequest;
      this.setLogin(credential);
    } else {
      this.setLogin(undefined);
    }
    this.authService.logout();
    // this.loginForm = this.fb.group({
    //    username: this.fb.control('', [Validators.required]),
    //      password: this.fb.control('', [Validators.required])
    //   // username: this.fb.control('admin@robocodex.com', [Validators.required]),
    //   // password: this.fb.control('rcx@dmin', [Validators.required])
    // });
  }
  private setLogin(authRequest?: AuthRequest) {
    if (!authRequest) authRequest = {
      username: '',
      password: '',
      rememberMe: false,
    } as AuthRequest;
    return this.loginForm = this.fb.group({
      username: this.fb.control(authRequest.username, [Validators.required]),
      password: this.fb.control(authRequest.password, [Validators.required]),
      rememberMe: this.fb.control(authRequest.rememberMe, [])
    });
  }
  onSignin(isLoggingIn: any) {
    if (this.loginForm.valid && (!isLoggingIn?.value || isLoggingIn.value.length === 0 || isLoggingIn.value === 'false')) {
      isLoggingIn.value = true;
      this.authService.signin(this.loginForm.value, isLoggingIn);
    } else {
      isLoggingIn.value = false;
      this.commonService.showMessage(500, 'Invalid credentials. Please try again.');
    }
  }
  public rememberMe(e) {
    e.target.checked ? undefined : this.commonService.removeLocalItem(Config.rememberMe);
  }
  fakeLogin() {
    let session = {
      token: 'dfgsdfgdsfgdfg',
      name: 'Tester',
      uid: 1,
      context: 'employee',
      time: new Date(),
      role: 'Principal Architect',
      privileges: ['dashboard']
    } as AuthResponse;
    this.authService.login(session);
    this.router.navigateByUrl('/employee/dashboard');
  }

  // On Forgotpassword link click
  onForgotpassword() {
    this.router.navigate(['forgot-password'], { relativeTo: this.route.parent });
  }

  // On Signup link click
  onSignup() {
    this.router.navigate(['sign-up'], { relativeTo: this.route.parent });
  }
  public toggleIcon() {
    const password = document.querySelector("#password");
    const type = password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    this.toggle = !this.toggle;
    this.toggle ? this.type = "password" : this.type = "text";
  }

  public resetPassword() {
    // this.modal.close();
    const modalRef = this.modalService.open(ForgotPasswordComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      size: 'md'
    });
  }
}
