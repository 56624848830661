<div class="bg-login">
	<div class="wrapper">
		<div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
			<div class="container-fluid">
				<div class="row row-cols-2 row-cols-lg-2 row-cols-xl-2 justify-content-center">
					<!-- <div class="col">
						<div class="card">
							<div class="card-body">
								<div class="mb-4 text-center">
									<img src="assets/images/logo-img.png" width="180" alt="" />
								</div>
							</div>
						</div>
					</div> -->
					<div class="col outer-section">
						<div class="row outer-block">
							<div class="card-body left">
								<div class=" logo-img text-center">
									<img src="assets/images/banner.png" width="270" alt="" />
								</div>
							</div>
							<div class="card-body right">
								<div class="border">
									<div class="text-center">
										<h3 class="">LOGIN</h3>
									</div>

									<div class="form-body">
										<form [formGroup]="loginForm" (ngSubmit)="onSignin(isLoggingIn)" class="row g-3">
											<div class="col-12">
												<label for="username" class="form-label">Username</label>
												<input type="text" class="form-control" id="username" (keydown.enter)="$event.preventDefault(); passwordInput.focus()"
													autocomplete="off" placeholder="Enter username" #usernameInput
													formControlName="username" autofocus="autofocus">
												<!-- Add the error message element -->
												<small class="form-text text-danger"
													*ngIf="!loginForm.get('username').valid && (loginForm.get('username').dirty || loginForm.get('username').touched)">
													Username is required.
												</small>
											</div>
											<div class="col-12">
												<label for="password" class="form-label">Password</label>
												<div class="input-group" id="show_hide_password">
													<input type="password" class="form-control border-end-0" (keydown.enter)="$event.preventDefault(); isLoggingIn.focus()"
														id="password"  placeholder="Enter password" autocomplete="off" #passwordInput 
														formControlName="password">
													<div class="input-group-append">
														<a (click)="$event.preventDefault()"
															class="input-group-text bg-transparent">
															<i [class]="type=='text'? 'bx bx-show':'bx bx-hide' "
																id="togglePassword" (click)="toggleIcon()"></i>
														</a>
													</div>
												</div>
												<!-- Add the error message element -->
												<small class="form-text text-danger"
													*ngIf="!loginForm.get('password').valid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
													Password is required.
												</small>
											</div>
											<div class="col-md-12 align-items-center d-flex">
												<div class="form-check mb-0">
													<input class="form-check-input" type="checkbox" id="gridCheck1" 
														formControlName="rememberMe" (click)="rememberMe($event)">
													<label class="form-check-label me-3" for="gridCheck1" style="font-size: 12px;">
														Remember me
													</label>
												</div>
												<a href="javascript:void(0)" class="cursor-pointer" style="font-size: 12px; margin-left: 5px;" (click)="resetPassword()">Forgot Password?</a>
											</div>
											<!-- <div class="col-md-6 text-end">	<a href="JavaScript:;" (click)="onForgotpassword()">Forgot Password?</a>
											</div> -->

											<!-- <div class="col-md-6">
												<div class="form-check form-switch">
													<input class="form-check-input" type="checkbox"
														id="flexSwitchCheckChecked" checked>
													<label class="form-check-label"
														for="flexSwitchCheckChecked">Remember Me</label>
												</div>
											</div> -->
											<!-- <div class="col-md-12 text-end"> <a href="JavaScript:;"
													style="font-size: 11px;" (click)="onForgotpassword()">Forgot
													Password ?</a>
											</div> -->
											<div class="col-12">
												<div class="d-grid">
                          <button type="submit" class="btn btn-primary" #isLoggingIn [ngClass]="{'no-event':isLoggingIn?.value=='true'}">
                            <ng-container *ngIf="isLoggingIn?.value=='true'">
                              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Logging in...
                            </ng-container>
                            <ng-container *ngIf="isLoggingIn?.value!='true'">
                              <i class="bx bxs-lock-open"></i>Login
                            </ng-container>
                          </button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--end row-->
			</div>
		</div>
	</div>
	<!--end wrapper-->
</div>
