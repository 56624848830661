import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { CompanyResolver } from './shared/resolvers/company.resolver';
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { SignInComponent } from './auth/sign-in/sign-in.component';

const routes: Routes = [
  {
    path: '', component: SignInComponent,
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'Full Views' }, children: Full_ROUTES, resolve: { companyId: CompanyResolver } },
  { path: '', component: ContentLayoutComponent, data: { title: 'Content Views' }, children: CONTENT_ROUTES },
  { path: '**', redirectTo: 'auth/sign-in' }
];
// const routes: Routes = [
//   { path: '', component: SignInComponent },
//   // { path: 'auth/sign-in', redirectTo: '', pathMatch: 'full' },
//   // { path: '**', redirectTo: '' },
//   { path: '', component: FullLayoutComponent, data: { title: 'Full Views' }, children: Full_ROUTES, resolve: { companyId: CompanyResolver } },
//   { path: '', component: ContentLayoutComponent, data: { title: 'Content Views' }, children: CONTENT_ROUTES },

// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
