<div class="modal-body sign-in">
  <div class="title text-center">
    <h3 class="modal-title">Reset Password</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <!-- Verify Email Form -->
  <ng-container *ngIf="!isEmailed || !isOtpSent">
    <form [formGroup]="verifyEmailForm">
      <div class="form-group sign-in_group">
        <label for="email" class="mb-1">Email<b class="text-secondary">*</b></label>
        <input type="email" class="form-control" id="email" placeholder="Enter your email..." autocomplete="off"
          formControlName="username" [ngClass]="{ 'is-invalid': isEmailSubmitted && e['username'].errors }">
        <div class="invalid-feedback" *ngIf="isEmailSubmitted && e.username.errors">
          <span *ngIf="e.username.errors.required">Please provide an email.</span>
          <span *ngIf="e.username.errors.pattern">Please provide a valid.</span>
        </div>
      </div>
      <div class="button-bottom d-flex justify-content-center mt-3">
        <button type="button" class="btn btn-primary" (click)="verifyEmail(verify)" 
        [ngClass]="{ verifying: verify?.value === 'true' }" #verify >
          <ng-container *ngIf="isVerifying; else verifyLabel">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-container>
          <ng-template #verifyLabel><i class='bx bx-file-find'></i>Verify Email</ng-template>
        </button>
      </div>
    </form>
  </ng-container>

  <!-- Verify OTP Form -->
  <ng-container *ngIf="isEmailed && !isVerified && isOtpSent">
    <form [formGroup]="verifyOtpForm">
      <div class="form-group otp-verification_group">
        <label for="email" class="mb-1">OTP<b class="text-secondary">*</b></label>
        <input type="text" class="form-control" id="otp" autocomplete="off" formControlName="passcode" mask="999999" maxlength="6"
        [showMaskTyped]="true" placeHolderCharacter="_" [ngClass]="{ 'is-invalid': isOtpSubmitted && o['passcode'].errors }">
          <div class="invalid-feedback" *ngIf="isOtpSubmitted && o.passcode.errors">
            <span *ngIf="o.passcode.errors.required"> Please enter a valid 6-digit OTP.</span>
            <!-- <span *ngIf="e.username.errors.pattern">Please provide a valid.</span> -->
          </div>
        <!-- <small><i class="bx bx-comment-dots"></i></small> -->
      </div>
      <div class="button-bottom d-flex justify-content-center mt-3">
        <button type="button" class="btn btn-primary" 
        (click)="verifyOtp(verify)"[ngClass]="{ verifying: verify?.value === 'true' }" #verify>
          <ng-container *ngIf="isVerifying; else verifyOtpText">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-container>
          <ng-template #verifyOtpText><i class='bx bx-file-find'></i>Verify OTP</ng-template>
        </button> 
      </div>
      <ng-container class="countdown mt-2" *ngIf="isOtpSent">
        <countdown [config]="{ leftTime: 180, format: 'mm:ss' }" (event)="countdownHandler($event)"></countdown>&nbsp;Seconds
      </ng-container>
    </form>
  </ng-container>

  <!-- Reset Password Form -->
  <ng-container *ngIf="isVerified">
    <form [formGroup]="resetForm">
      <div class="form-group sign-in_group">
        <label for="email" class="mb-1">Email</label>
        <input type="email" class="form-control" id="email" [value]="e['username'].value" placeholder="Enter Email..." readonly>
      </div>
      <div class="form-group sign-in_group">
        <label for="password" class="mb-1">Password<b class="text-danger">*</b></label>
        <input type="password" class="form-control" id="password" formControlName="password" #password [ngClass]="{ 'is-invalid': submit && r['password'].errors }">
        <span><i [class]="password?.type === 'password' ? 'bx bx-low-vision':'bx bx-show'" (click)="showPassword(password)"></i></span>
        <div class="invalid-feedback" *ngIf="submit && r['password'].errors">
          <span *ngIf="r['password'].errors['required']">Please enter a valid password.</span>
          <span *ngIf="r['password'].errors['pattern']">Password should contain at least 6 characters.</span>
        </div>
      </div>
      <div class="form-group sign-in_group">
        <label for="confirmPassword" class="mb-1">Confirm Password<b class="text-danger">*</b></label>
        <input type="password" class="form-control" id="confirm" formControlName="confirm" [ngClass]="{ 'is-invalid': submit && (r['confirm'].errors || r['confirm'].value !== r['password'].value) }">
          <div class="invalid-feedback" *ngIf="submit && (r['confirm'].errors || r['confirm'].value !== r['password'].value)">
            <span *ngIf="r['confirm'].errors?.['required']">Please enter a valid confirm password.</span>
            <span *ngIf="r['confirm'].value !== r['password'].value">Password and confirm password doesn't match.</span>
          </div>
      </div>
      <div class="button-bottom d-flex justify-content-center mt-3">
        <button type="button" class="btn btn-primary" 
        (click)="resetPassword(verify)"[ngClass]="{ verifying: verify?.value === 'true' }" #verify>
          <ng-container *ngIf="isVerifying; else resetLabel">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-container>
          <ng-template #resetLabel><i class="bx bxs-lock-open"></i>Reset Password</ng-template>
        </button>
      </div>
    </form>
  </ng-container>
</div>
