import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Address, Role, User } from '../models/context';
import { Company, EmployeeInsurance, Holiday, Insurance, InsurancePlan, Message, Nominee, Title, Workday, Performance, Metrics, SocialMedia } from '../models/domain';
import { ProfileInfo } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient) { }

  //HOLIDAY API'S
  public createHoliday(holiday: Holiday): Observable<Holiday> {
    return this.httpClient.post<Holiday>('/api/holiday/v1', holiday);
  }

  public getHolidays(company: number, date: string, disabled?: boolean): Observable<Holiday[]> {
    return this.httpClient.get<Holiday[]>('/api/holiday/v1', { params: { company, date, ...(disabled && { disabled }) } });

  }

  public getHoliday(id: number): Observable<Holiday> {
    return this.httpClient.get<Holiday>(`/api/holiday/v1/${id}`);
  }

  public updateHoliday(id: number, holiday: Holiday): Observable<Holiday> {
    return this.httpClient.put<Holiday>(`/api/holiday/v1/${id}`, holiday);
  }

  public deleteHoliday(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/holiday/v1/${id}`);
  }

  public toggleHolidayStatus(holiday: Holiday): Observable<Holiday> {
    return this.httpClient.put<Holiday>(`/api/holiday/v1/${holiday.id}`, holiday);
  }

  //DEFAULT API'S
  public getHolidayTypes(): string[] {
    return [
      'Public Holiday',
      'Local Holiday',
      'Religious Holiday',
      'Bank Holiday',
      'National Holiday',
      'Festival Holiday',
      'Government Holiday',
      'Cultural Holiday',
      'School Holiday',
      'Corporate Holiday'
    ];
  }

  public getWeekdays(): Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/weekday');
  }

  //PROFILE INFO

  public getEmployeeProfileInfo(): Observable<ProfileInfo> {
    return this.httpClient.get<ProfileInfo>(`/api/profile/v1/employee-info`);
  }

  public getAdminProfileInfo(): Observable<ProfileInfo> {
    return this.httpClient.get<ProfileInfo>(`/api/profile/v1/company-info`);
  }

  //COMPANY API'S
  public createCompany(company: Company): Observable<Company> {
    return this.httpClient.post<Company>('/api/company/v1', company);
  }

  public getCompanies(disabled?: boolean): Observable<Company[]> {
    return this.httpClient.get<Company[]>('/api/company/v1', { params: { ...(disabled && { disabled }) } });
  }

  public getCompany(id: number): Observable<Company> {
    return this.httpClient.get<Company>(`/api/company/v1/${id}`);
  }

  public updateCompany(id: number, company: Company): Observable<Company> {
    return this.httpClient.put<Company>(`/api/company/v1/${id}`, company);
  }

  public deleteCompany(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/company/v1/${id}`);
  }

  public toggleCompanyStatus(company: Company): Observable<Company> {
    return this.httpClient.put<Company>(`/api/company/v1/${company.id}`, company);
  }

  public getPeriodTypes(): string[] {
    return [
      'FISCAL_YEAR',
      'CALENDAR_YEAR',
      'QUARTERLY',
      'MONTHLY',
      'WEEKLY'
    ];
  }

  //ROLE API'S
  public createRole(role: Role): Observable<Role> {
    return this.httpClient.post<Role>('/api/role/v1', role);
  }

  public getRoles(companyId: number, disabled?: boolean): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`/api/role/v1`, { params: { companyId, ...(disabled != null && { disabled }) } });
  }

  public getRole(id: number): Observable<Role> {
    return this.httpClient.get<Role>(`/api/role/v1/${id}`);
  }

  public updateRole(id: number, role: Role): Observable<Message> {
    return this.httpClient.put<Message>(`/api/role/v1/${id}`, role);
  }

  public deleteRole(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/role/v1/${id}`);
  }

  public toggleRoleStatus(role: Role): Observable<Role> {
    return this.httpClient.put<Role>(`/api/role/v1/${role.id}`, role);
  }

  public getPersonas() {
    return this.httpClient.get<string[]>('/api/role/v1/persona');
  }

  public getPrivileges() {
    return this.httpClient.get<string[]>('/api/role/v1/privilege');
  }

  public getDefaultModules(): Observable<Map<string, string>> {
    return this.httpClient.get<Map<string, string>>(`/api/menu/module`);
  }

  public getUserRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`/api/role/v1`);
  }

  //USER API'S
  public getUsers(company?: number): Observable<User[]> {
    return this.httpClient.get<User[]>('/api/employee/v1/user', { params: { ...company && { company } } });
  }

  public getUser(id: number): Observable<User> {
    return this.httpClient.get<User>(`/api/employee/v1/user/${id}`);
  }

  public createUser(user: User): Observable<Message> {
    return this.httpClient.post<Message>('/api/employee/v1/user', user);
  }

  public updateUser(id: number, user: User): Observable<Message> {
    return this.httpClient.put<Message>(`/api/employee/v1/user/${id}`, user);
  }

  public deleteUser(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/employee/v1/user/${id}`);
  }

  public createCompanyUser(companyId: number, user: User): Observable<Message> {
    return this.httpClient.post<Message>(`/api/employee/v1/user/admin/${companyId}`, user);
  }

  public getCompanyUser(companyId: number): Observable<User> {
    return this.httpClient.get<User>(`/api/employee/v1/user/admin/${companyId}`);
  }

  public toggleUserStatus(user: User): Observable<Message> {
    return this.httpClient.put<Message>(`/api/employee/v1/user/${user.id}`, user);
  }

  //ADDRESS API'S
  public getAddresses(employee: number, type: string): Observable<Address[]> {
    return this.httpClient.get<Address[]>(`/api/employee/v1/address?employee=${employee}&type=${type}`);
  }

  public getAddress(id: number): Observable<Address> {
    return this.httpClient.get<Address>(`/api/employee/v1/address/${id}`);
  }

  public createAddress(address: Address): Observable<Address> {
    return this.httpClient.post<Address>('/api/employee/v1/address', address);
  }

  public updateAddress(id: number, address: Address): Observable<Address> {
    return this.httpClient.put<Address>(`/api/employee/v1/address/${id}`, address);
  }

  public deleteAddress(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/employee/v1/address/${id}`);
  }

  //WORKDAY API'S
  public createWorkday(workday: Workday): Observable<Workday> {
    return this.httpClient.post<Workday>('/api/workday/v1', workday);
  }

  public getWorkday(id: number): Observable<Workday> {
    return this.httpClient.get<Workday>(`/api/workday/v1/${id}`);
  }

  public getWorkdays(company: number, disabled?: boolean): Observable<Workday[]> {
    return this.httpClient.get<Workday[]>('/api/workday/v1', { params: { company, ...(disabled && { disabled }) } });
  }

  public updateWorkday(id: number, workday: Workday): Observable<Workday> {
    return this.httpClient.put<Workday>(`/api/workday/v1/${id}`, workday);
  }

  public deleteWorkday(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/workday/v1/${id}`);
  }

  public toggleWorkdayStatus(workday: Workday): Observable<Workday> {
    return this.httpClient.put<Workday>(`/api/workday/v1/${workday.id}`, workday);
  }

  //TITLE API'S
  public createTitle(title: Title): Observable<Title> {
    return this.httpClient.post<Title>('/api/title/v1', title);
  }

  public getTitle(id: number): Observable<Title> {
    return this.httpClient.get<Title>(`/api/title/v1/${id}`);
  }

  public getTitles(company: number, band?: string, level?: number, disabled?: boolean): Observable<Title[]> {
    return this.httpClient.get<Title[]>('/api/title/v1', { params: { company, ...(band && { band }), ...(level && { level }), ...(disabled!=null && { disabled }) } });
  }

  public updateTitle(id: number, title: Title): Observable<Title> {
    return this.httpClient.put<Title>(`/api/title/v1/${id}`, title);
  }

  public deleteTitle(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/title/v1/${id}`);
  }

  public toggleTitleStatus(title: Title): Observable<Title> {
    return this.httpClient.put<Title>(`/api/title/v1/${title.id}`, title);
  }

  //INSURANCE

  public createInsurance(insurance: Insurance): Observable<Message> {
    return this.httpClient.post<Message>('/api/setup/insurance', insurance);
  }

  public getInsurances(disabled?: boolean): Observable<Insurance[]> {
    return this.httpClient.get<Insurance[]>('/api/setup/insurances',{ params: { ...disabled != null && { disabled } } });
  }

  public getInsurance(id: number): Observable<Insurance> {
    return this.httpClient.get<Insurance>(`/api/setup/insurance/${id}`);
  }

  public updateInsurance(id: number, insurance: Insurance): Observable<Message> {
    return this.httpClient.put<Message>(`/api/setup/insurance/${id}`, insurance);
  }

  public deleteInsurance(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/setup/insurance/${id}`);
  }

  public toggleInsuranceStatus(insurance: Insurance): Observable<Insurance> {
    return this.httpClient.put<Insurance>(`/api/setup/insurance/${insurance.id}`, insurance);
  }

  public getInsuranceStatus(insuranceId:number):Observable<boolean>{
    return this.httpClient.get<boolean>(`/api/setup/insurance-editable/${insuranceId}`);
  }
  
//INSURANCE-PLAN


  public createInsurancePlan(insurancePlan: InsurancePlan[]): Observable<Message> {
    return this.httpClient.post<Message>('/api/setup/insurance-plan', insurancePlan);
  }

  public getInsurancePlans(insuranceId:number, disabled?: boolean): Observable<InsurancePlan[]> {
    return this.httpClient.get<InsurancePlan[]>('/api/setup/insurance-plans', { params: {insuranceId, ...(disabled && { disabled }) } });
  }

  public getInsurancePlan(id: number): Observable<InsurancePlan> {
    return this.httpClient.get<InsurancePlan>(`/api/setup/insurance-plan/${id}`);
  }

  public updateInsurancePlan(insurancePlan: InsurancePlan[]): Observable<Message> {
    return this.httpClient.put<Message>(`/api/setup/insurance-plan`, insurancePlan);
  }

  public deleteInsurancePlan(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/setup/insurance-plan/${id}`);
  }

  public getInsuranceType():Observable<string>{
    return this.httpClient.get<string>(`/api/setup/insurance-plan/type`);
  }

// EMPLOYEE INSURANCE

public createEmpInsurance(empInsurance: EmployeeInsurance): Observable<Message> {
  return this.httpClient.post<Message>('/api/setup/employee-insurance', empInsurance);
}

public getEmpInsurances(employeeId: number, active:boolean, disabled?: boolean): Observable<EmployeeInsurance[]> {
  return this.httpClient.get<EmployeeInsurance[]>('/api/setup/employee-insurances', { params: {employeeId,...(active && { active }), ...(disabled && { disabled }) } });
}

public getEmpInsurance(id: number): Observable<EmployeeInsurance> {
  return this.httpClient.get<EmployeeInsurance>(`/api/setup/employee-insurance/${id}`);
}

public updateEmpInsurance(id: number, empInsurance: EmployeeInsurance): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/employee-insurance/${id}`, empInsurance);
}

public deleteEmpInsurance(id: number): Observable<Message> {
  return this.httpClient.delete<Message>(`/api/setup/employee-insurance/${id}`);
}

public toggleEmpInsuranceStatus(empInsurance: EmployeeInsurance): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/employee-insurance/${empInsurance.id}`, empInsurance);
}


//EMPLOYEE INSURANCE PLAN

public createEmpInsurancePlan(empInsurancePlan: EmployeeInsurance[]): Observable<Message> {
  return this.httpClient.post<Message>('/api/setup/employee-insurance', empInsurancePlan);
}

public getEmpInsurancePlans(insuranceId:number, disabled?: boolean): Observable<EmployeeInsurance[]> {
  return this.httpClient.get<EmployeeInsurance[]>('/api/setup/insurance-plans', { params: {insuranceId, ...(disabled && { disabled }) } });
}

public getEmpInsurancePlan(id: number): Observable<InsurancePlan> {
  return this.httpClient.get<InsurancePlan>(`/api/setup/insurance-plan/${id}`);
}

public updateEmpInsurancePlan(insurancePlan: InsurancePlan[]): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/insurance-plan`, insurancePlan);
}

public deleteEmpInsurancePlan(id: number): Observable<Message> {
  return this.httpClient.delete<Message>(`/api/setup/insurance-plan/${id}`);
}

public getEmpInsuranceType():Observable<string>{
  return this.httpClient.get<string>(`/api/setup/insurance-plan/type`);
}

// NOMINEE

public createNominee(nominee: Nominee[]): Observable<Message> {
  return this.httpClient.post<Message>('/api/setup/nominee', nominee);
}

public getNominees(empInsuranceId:number, disabled?: boolean): Observable<Nominee[]> {
  return this.httpClient.get<Nominee[]>('/api/setup/nominees', { params: {empInsuranceId, ...(disabled && { disabled }) } });
}

public getNminee(id: number): Observable<Nominee> {
  return this.httpClient.get<Nominee>(`/api/setup/nominee/${id}`);
}

public updateNominee(nominee: Nominee[]): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/nominee`, nominee);
}

public deleteNominee(id: number): Observable<Message> {
  return this.httpClient.delete<Message>(`/api/setup/nominee/${id}`);
}

public getNomineeRelation():Observable<string>{
  return this.httpClient.get<string>(`/api/setup/nominee/relation`);
}


// PERFORMANCE 

public createPerformance(performance: Performance): Observable<Message> {
  return this.httpClient.post<Message>('/api/setup/performance', performance);
}

public getPerformances(disabled?: boolean): Observable<Performance[]> {
  return this.httpClient.get<Performance[]>('/api/setup/performances', { params: {...(disabled && { disabled }) } });
}

public getPerformance(id: number): Observable<Performance> {
  return this.httpClient.get<Performance>(`/api/setup/performance/${id}`);
}

public updatePerformance(id: number, performance: Performance): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/performance/${id}`, performance);
}

public deletePerformance(id: number): Observable<Message> {
  return this.httpClient.delete<Message>(`/api/setup/performance/${id}`);
}

public togglePerformanceStatus(performance: Performance): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/performance/${performance.id}`, performance);
}

// MERICS

public createMetric(metrics: Metrics): Observable<Message> {
  return this.httpClient.post<Message>('/api/setup/metric', metrics);
}

public getMetrics(disabled?: boolean): Observable<Metrics[]> {
  return this.httpClient.get<Metrics[]>('/api/setup/metrics', { params: {...(disabled && { disabled }) } });
}

public getMetric(id: number): Observable<Metrics> {
  return this.httpClient.get<Metrics>(`/api/setup/metric/${id}`);
}

public updateMetric(id: number, metrics: Metrics): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/metric/${id}`, metrics);
}

public deleteMetric(id: number): Observable<Message> {
  return this.httpClient.delete<Message>(`/api/setup/metric/${id}`);
}

public toggleMetricStatus(metrics: Metrics): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/metric/${metrics.id}`, metrics);
}

//SOCIALMEDIA

public createSocialMedia(socialMedia: SocialMedia): Observable<Message> {
  return this.httpClient.post<Message>('/api/setup/social', socialMedia);
}

public getSocialMedias(disabled?: boolean): Observable<SocialMedia[]> {
  return this.httpClient.get<SocialMedia[]>('/api/setup/socials', { params: { ...disabled != null && { disabled } } });
}

public getSocialMedia(id: number): Observable<SocialMedia> {
  return this.httpClient.get<SocialMedia>(`/api/setup/social/${id}`);
}

public updateSocialMedia(id: number, socialMedia: SocialMedia): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/social/${id}`, socialMedia);
}

public deleteSocialMedia(id: number): Observable<Message> {
  return this.httpClient.delete<Message>(`/api/setup/social/${id}`);
}

public toggleSocialMedia(socialMedia: SocialMedia): Observable<Message> {
  return this.httpClient.put<Message>(`/api/setup/social/${socialMedia.id}`, socialMedia);
}

}
