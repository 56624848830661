import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role, User } from '../models/context';
import { EmployeeData, Epoch, Family, Info, Message, Quarter } from '../models/domain';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) { }


  //EMPLOYEE

  public getEmployees(company?: number): Observable<EmployeeData[]> {
    return this.httpClient.get<EmployeeData[]>('/api/employee/v1', { params: { ...company && { company } } });
  }

  public getEmployee(id: number): Observable<EmployeeData> {
    return this.httpClient.get<EmployeeData>(`/api/employee/v1/${id}`);
  }

  public createEmployee(employee: EmployeeData): Observable<Message> {
    return this.httpClient.post<Message>('/api/employee/v1', employee);
  }

  public updateEmployee(id: number, employee: EmployeeData): Observable<Message> {
    return this.httpClient.put<Message>(`/api/employee/v1/${id}`, employee);
  }

  public deleteEmployee(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/employee/v1/${id}`);
  }

  public toggleEmployeeStatus(employee: EmployeeData): Observable<Message> {
    return this.httpClient.put<Message>(`/api/employee/v1/${employee.employeeData.id}`, employee);
  }

  //USER API'S

  // public getUsers(company: number): Observable<User[]> {
  //   if (company) return this.httpClient.get<User[]>(`/api/employee/v1/user?company=${company}`);
  //   else {
  //     return this.httpClient.get<User[]>(`/api/employee/v1/user`);
  //   }
  // }

  public createCompanyUser(companyId: number, user: User): Observable<Message> {
    return this.httpClient.post<Message>(`/api/employee/v1/user/admin/${companyId}`, user);
  }
  public getCompanyUser(companyId: number): Observable<User> {
    return this.httpClient.get<User>(`/api/employee/v1/user/admin/${companyId}`);
  }

  //role
  public getRoles(companyId: number, disabled?: boolean): Observable<Role[]> {
    if (companyId) return this.httpClient.get<Role[]>(`/api/role/v1?companyId=${companyId}`);
    else if (disabled === false) {
      return this.httpClient.get<Role[]>(`/api/role/v1?disabled=${disabled}`);
    } else {
      return this.httpClient.get<Role[]>(`/api/role/v1`);
    }
  }
  public getRole(id: number): Observable<Role> {
    return this.httpClient.get<Role>(`/api/role/v1/${id}`);
  }
  public createRole(role: Role): Observable<Role> {
    return this.httpClient.post<Role>('/api/role/v1', role);
  }
  public updateRole(id: number, role: Role): Observable<Role> {
    return this.httpClient.put<Role>(`/api/role/v1/${id}`, role);
  }
  public deleteRole(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/role/v1/${id}`);
  }
  public getPersonas() {
    return this.httpClient.get<string[]>('/api/role/v1/persona');
  }
  public getPrivileges() {
    return this.httpClient.get<string[]>('/api/role/v1/privilege');
  }
  public getDefaultModules(): Observable<Map<string, string>> {
    return this.httpClient.get<Map<string, string>>(`/api/menu/module`);
  }
  // family
  public getFamiles(employee: number,nominee?:boolean): Observable<Family[]> {
    if (employee) return this.httpClient.get<Family[]>(`/api/family/v1?employee=${employee}`,{ params: { ...nominee != null && { nominee } } });
    return this.httpClient.get<Family[]>(`/api/family/v1`);
  }
  public getFamily(id: number): Observable<Family> {
    return this.httpClient.get<Family>(`/api/family/v1/${id}`);
  }
  public createFamily(family: Family): Observable<Family> {
    return this.httpClient.post<Family>('/api/family/v1', family);
  }
  public updateFamily(id: number, family: Family): Observable<Message> {
    return this.httpClient.put<Message>(`/api/family/v1/${id}`, family);
  }
  public deleteFamily(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/family/v1/${id}`);
  }
  public toggleFamilyStatus(family: Family): Observable<Family> {
    return this.httpClient.put<Family>(`/api/family/v1/${family.id}`, family);
  }
  public getRelations(): Observable<string[]> {
    return this.httpClient.get<string[]>('/api/family/v1/relation');
  }

  public getInfo(): Observable<Info> {
    return this.httpClient.get<Info>('/api/company/v1/info');
  }
  //Epoch
  public getEpochs(disabled?: boolean): Observable<Epoch[]> {
    return this.httpClient.get<Epoch[]>('/api/setup/epochs', { params: { ...disabled != null && { disabled } } });
  }
  public getEpoch(id: number): Observable<Epoch> {
    return this.httpClient.get<Epoch>(`/api/setup/epoch/${id}`);
  }
  public createEpoch(epoch: Epoch): Observable<Message> {
    return this.httpClient.post<Message>('/api/setup/epoch', epoch);
  }
  public updateEpoch(id: number, epoch: Epoch): Observable<Message> {
    return this.httpClient.put<Message>(`/api/setup/epoch/${id}`, epoch);
  }
  public deleteEpoch(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/setup/epoch/${id}`);
  }
  public toggleEpochStatus(epoch: Epoch): Observable<Message> {
    return this.httpClient.put<Message>(`/api/setup/epoch/${epoch.id}`, epoch);
  }
  //Quarter
  public getQuarters(epochId?:number): Observable<Quarter[]> {
    return this.httpClient.get<Quarter[]>('/api/setup/quarters', { params: { ...(epochId && { epochId }) } });
  }
  public getQuarter(id: number): Observable<Quarter> {
    return this.httpClient.get<Quarter>(`/api/setup/quarter/${id}`);
  }
  public createQuarter(quarter: Quarter): Observable<Message> {
    return this.httpClient.post<Message>('/api/setup/quarter', quarter);
  }
  public updateQuarter(id: number, quarter: Quarter): Observable<Message> {
    return this.httpClient.put<Message>(`/api/setup/quarter/${id}`, quarter);
  }
  public deleteQuarter(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/setup/quarter/${id}`);
  }
  public toggleQuarterStatus(quarter: Quarter): Observable<Message> {
    return this.httpClient.put<Message>(`/api/setup/quarter/${quarter.id}`, quarter);
  }
}
