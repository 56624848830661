import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CardData, Graph,Notification } from '../models/insight';
import { map } from 'rxjs/operators';
import { Message } from '../models/domain';

@Injectable({
  providedIn: 'root'
})
export class InsightService {


  constructor(private httpClient: HttpClient) { }

  //PLATFORM API'S
  public getTotalCompaniesCount(): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/admin/company`);
  }

  public getActiveUsersCount(companyId?: number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/admin/active-users`, { params: { ...(companyId && { companyId }) } });
  }

  public getTotalUserCount(companyId?: number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/admin/user-count`, { params: { ...(companyId && { companyId }) } });
  }

  public getTotalUsersCount$(companyId: number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/admin/user/${companyId}`).pipe(
      map((response: any) => {
        return { name: 'Total Users', count: response.graphData[0]?.data[0] } as CardData;
      }),
    );
  }

  public getExpiredLicenseCount(): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/admin/licence`);
  }

  public getUserInsightMetrics(company: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/admin/user/${company}`, { params: { ...date && { date } } });
  }

  public getEmployeeInsightMetrics(company: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/admin/employee/${company}`, { params: { ...date && { date } } });
  }

  //ADMIN API'S
  public getTotalEmployeesCount(companyId?: number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/client-admin/employee`, { params: { ...(companyId && { companyId }) } });
  }

  public getAdminActiveUsersCount(companyId: number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/client-admin/active-users`, { params: { ...(companyId && { companyId }) } });
  }

  //HR Dashboard
  public getTotalEmployeeCount(): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/hr/employees-count`);
  }

  public getTotalAttandanceCount(): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/hr/employees-attendance`);
  }

  public getTotalOverDuePaymentCount(): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/hr/payment-overdue`);
  }

  public getTotalCompletedPaymentCount(): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/hr/payment-completed`);
  }

  public getJoinees(date?: any): Observable<CardData[]> {
    return this.httpClient.get<CardData[]>(`/api/home/dashboard/hr/joinee`, { params: { ...date && { date } } });
  }

  public getHolidays(date?: any): Observable<CardData[]> {
    return this.httpClient.get<CardData[]>(`/api/home/dashboard/hr/holiday`, { params: { ...date && { date } } });
  }

  public getBirthdays(date?: any): Observable<CardData[]> {
    return this.httpClient.get<CardData[]>(`/api/home/dashboard/hr/birthday`, { params: { ...date && { date } } });
  }

  public getRegularInsightMetrics(date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/hr/regular`, { params: { ...date && { date } } });
  }

  public getLeaveInsightMetrics(date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/hr/leave`, { params: { ...date && { date } } });
  }
  public getAbsenceInsightMetrics(date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/hr/absence`, { params: { ...date && { date } } });
  }

  public getHrEmployeeRegularInsightMetrics(employee: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/hr/employee/regular/${employee}`, { params: { ...date && { date } } });
  }

  public getHrEmployeeLeaveInsightMetrics(employee: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/hr/employee/leave/${employee}`, { params: { ...date && { date } } });
  }
  public getHrEmployeeAbsenceInsightMetrics(employee: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/hr/employee/absence/${employee}`, { params: { ...date && { date } } });
  }
  //EMPLOYEE
  public getTotalLeavesCount(employeeId:number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/employee/leave-count/${employeeId}`);
  }

  public getTotalRegularCount(employeeId:number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/employee/regular-count/${employeeId}`);
  }

  public getTotalAbsenceCount(employeeId:number): Observable<CardData> {
    return this.httpClient.get<CardData>(`/api/home/dashboard/employee/absence-count/${employeeId}`);
  }

  public getEmployeeJoinees(date?: any): Observable<CardData[]> {
    return this.httpClient.get<CardData[]>(`/api/home/dashboard/employee/joinee`, { params: { ...date && { date } } });
  }

  public getEmployeeHolidays(date?: any): Observable<CardData[]> {
    return this.httpClient.get<CardData[]>(`/api/home/dashboard/employee/holiday`, { params: { ...date && { date } } });
  }

  public getEmployeeBirthdays(date?: any): Observable<CardData[]> {
    return this.httpClient.get<CardData[]>(`/api/home/dashboard/employee/birthday`, { params: { ...date && { date } } });
  }

  public getEmployeeRegular(employee: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/employee/regular/${employee}`, { params: { ...date && { date } } });
  }
  public getEmployeeLeave(employee: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/employee/leave/${employee}`, { params: { ...date && { date } } });
  }
  public getEmployeeAbsence(employee: number, date?: any): Observable<Graph> {
    return this.httpClient.get<Graph>(`/api/home/dashboard/employee/absence/${employee}`, { params: { ...date && { date } } });
  }
  // NOTIFICATION
  public getNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>('/api/home/notification/messages');
  }
  public getUnseenNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>('/api/home/notification/message-unseen');
  }
  public updateNotification(ids:number[]): Observable<Message> {
    return this.httpClient.patch<Message>(`/api/home/notification?ids=${ids}`,{});
  }
}
