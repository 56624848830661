export const RegExp = {
  letters: /^[A-Za-z\s]+$/,
  code: /^[a-zA-Z0-9]*$/,
  general: /^.{1,35}$/,
  name: /^[a-zA-Z\d\s\-_!@#$%^&*()'"/]{1,250}$/,
  holidayName: /^[\s\S]{1,250}$/,
  text: /^(?=[a-zA-Z&_\- ]{1,250}$)[a-zA-Z&_\-][a-zA-Z&_\- ]*$/,
  // description: /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-\s]*$/i,
  description:/^[\p{L}\p{N}\p{P}\p{Zs}]{1,256}$/u,
  address:/^.{1,250}$/,
  zip: /^(\d{4}|\d{6})$/,
  email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
  mobile: /^(\+91\s\d{10}|0\d{10}|\d{10})$/,
  phone: /^\d{8,13}$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
  website: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  gst: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  aadharNumber: /^\d{4}\s?\d{4}\s?\d{4}$/,
  aadhar: /^\d{12}$/,
  percentage: /^100(\.00?)?$|^\d{0,2}(\.\d{1,2})?$/,
  username: /^[a-zA-Z0-9_-]{3,16}$/,
  ifsccode: '^[A-Z]{4}0[A-Z0-9]{6}$',
  account: /^\d{9,18}$/,
  latAndLog: /^\d+(\.\d+)?$/,
  comment:/^[a-zA-Z\d]{1,250}$/,
  basicSalary:/^\d+$/,
  label: /^(?=[\w&_\-!@#$%^*()=+[\]{};:'",.<>?~ ]{1,250}$)[\w&_\-!@#$%^*()=+[\]{};:'",.<>?~ ][\w&_\-!@#$%^*()=+[\]{};:'",.<>?~ ]*$/,
  domain: /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.[A-Za-z]{2,6}(\.[A-Za-z]{2,6})?$/,
  number: /^\d{1,100}$/,
  worth: /^\d+(\.\d{1,2})?$/,
  numeric:/^(0\.[1-9]|1)$/,
  countryCode: /^[A-Z]*$/,
  offset: /^[+-]\d{2}:\d{2}$/,
  ac: /^\d+$/,
  pan: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  alphaNumeric:'^[a-zA-Z0-9_-]+$',
  alphaNumericspace:'^[a-zA-Z0-9 _-]+$',
  coverage:'^[0-9,]+$',
  contact: /^(\+91[-\s]?)?(\d{3}[-\s]?\d{8}|\d{4}[-\s]?\d{6}|\d{10})$/,
  selfRating: /^0(\.[1-9])$|^1(\.0)?$/
}
