import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company, Employee, EmployeeData } from '../models/domain';
import { Menu } from './navigation.service';
import { CommonService } from './common.service';
import { Config } from '../models/config';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private contextReinited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private notificationReinited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private companies$: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);
  private employees$: BehaviorSubject<EmployeeData[]> = new BehaviorSubject<EmployeeData[]>([]);
  private menus$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);
  private privilege$: BehaviorSubject<string> = new BehaviorSubject<string>('VIEW');
  private sildeMenus: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(this.enryptionService.decryptToArray(Config.menu_key));
  constructor(private commonService: CommonService, private enryptionService: EncryptionService) { }

  public reInitContext(inited: boolean = true) {
    this.contextReinited.next(inited);
  }
  public reInitNotification(inited: boolean = true) {
    this.notificationReinited.next(inited);
  }

  public onContextReInit(): Observable<boolean> {
    return this.contextReinited;
  }

  public setCompanies(companies: Company[]) {
    this.companies$.next(companies);
  }

  public companies(): Observable<Company[]> {
    return this.companies$;
  }

  public setEmployees(employees: EmployeeData[]) {
    this.employees$.next(employees);
  }

  public employees(): Observable<EmployeeData[]> {
    return this.employees$;
  }

  public setMenus(menus: Menu[]) {
    this.menus$.next(menus);
  }

  public menus(): Observable<Menu[]> {
    return this.menus$;
  }

  public setPrivilege(privilege: string) {
    this.privilege$.next(privilege);
  }

  public privilege(): Observable<string> {
    return this.privilege$;
  }

  public setSideMenus(menus: string[]) {
    this.enryptionService.encryptAndStore(menus,Config.menu_key);
    this.sildeMenus.next(menus);
  }

  public sideMenu() {
    return this.sildeMenus;
  }

  public getSidebarMenus() {
    return this.sildeMenus?.getValue();
  }
}
