import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,private commonService:CommonService) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.isAuthorized() && request.url.startsWith('/api/')) {
      let token = this.authService.getToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            "X-Requested-With": "XMLHttpRequest",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      }
    }
    return next.handle(request);
  }
}