import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegExp } from 'src/app/core/config/regex';
import { Message } from 'src/app/shared/models/domain';
import { AuthService, ResetPassword } from 'src/app/shared/services/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public resetForm: FormGroup;
  public verifyEmailForm: FormGroup;
  public verifyOtpForm: FormGroup;
  public submit: boolean = false;
  public isEmailed: boolean = false;
  public isVerified: boolean = false;
  public confirmPassword: boolean;
  public isOtpSent: boolean = true;
  public isEmailSubmitted: boolean = false;
  public isOtpSubmitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private commonService: CommonService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.setVerifyEmail();
    this.setVerifyOtp();
    this.setResetPassword();
  }

  private setVerifyEmail() {
    this.verifyEmailForm = this.fb.group({
      username: this.fb.control("", [Validators.required, Validators.pattern(RegExp.email)]),
    });
  }

  public verifyEmail(verifying?: any) {
    this.isEmailSubmitted = true;
    let value = this.verifyEmailForm?.value;
    if (this.verifyEmailForm?.valid) {
      verifying.value = true;
      if (!this.isEmailed) {
        this.authService.sendPasscode(value.username).subscribe({
          next: (response: Message) => {
            if (response) {
              this.isEmailed = true;
              verifying.value = false;
              this.commonService.setToastr(200,response.text);
            } else {
              verifying.value = false;
              this.commonService.setToastr(500 ,response.text);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.commonService.setToastr(500,`${value.username} is not found!`);  
            verifying.value = false;
          }
        });
      }
    }
  }

  private setVerifyOtp() {
    this.verifyOtpForm = this.fb.group({
      passcode: this.fb.control("", [Validators.required]),
    });
  }


  public verifyOtp(verifying?: any) {
    this.isOtpSubmitted = true;
    let value = this.verifyOtpForm.value;
    if (this.verifyOtpForm.valid) {
      verifying.value = true;
      this.isOtpSent = true;
      this.authService.validatePasscode(this.verifyEmailForm.get('username')?.value, value.passcode).subscribe({
        next: (response: Message) => {
          if (!response.failed) {
            this.isVerified = true;
            verifying.value = false;
            this.commonService.setToastr(200, response.text);
          } else {
            verifying.value = false;
            this.commonService.setToastr(500, response.text);
          }
        },
        error: (error: HttpErrorResponse) => {
          verifying.value = false;
        }
      });
    }
  }

  private setResetPassword() {
    this.resetForm = this.fb.group({
      username: this.fb.control(this.verifyEmailForm.get('username')?.value),
      password: this.fb.control("", [Validators.required, Validators.pattern(RegExp.password)]),
      confirm: this.fb.control("", [Validators.required, Validators.pattern(RegExp.password)]),
    });
  }

  public resetPassword(verifying?: any) {
    this.submit = true;
    let formValue = this.resetForm.value;
    if(formValue.password !== formValue.confirm){
      return
    }
    let reset = { username: this.verifyEmailForm.get('username')?.value, password: formValue.password } as ResetPassword;
    if (this.resetForm.valid) {
      verifying.value = true;
      if (this.isVerified) {
        this.authService.resetPassword(reset).subscribe({
          next: (response: Message) => {
            if (!response.failed) {
              verifying.value = false;
              this.activeModal.close();
              this.commonService.setToastr(200, `Password reset successfully. You can now sign in with the new password!`);
            } else {
              verifying.value = false;
              this.commonService.setToastr(500, `Something went wrong, Please try again!`);
            }
          },
          error: (error: HttpErrorResponse) => {
            verifying.value = false;
          }
        });
      }
    }
  }

  get r() {
    return this.resetForm.controls;
  }
  get e() {
    return this.verifyEmailForm.controls;
  }

  get o() {
    return this.verifyOtpForm.controls;
  }

  public showPassword(value: any) {
    this.commonService?.showPassword(value);
  }

  public countdownHandler(e: any) {
    if (e?.action === 'done') {
      this.isOtpSent = false;
      setTimeout(() => {
        this.commonService.setToastr(500, `Otp expired !`)
      }, 2000)
    }
  }

}




