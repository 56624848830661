import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Context } from '../models/context';
import { Company, Image, Message } from '../models/domain';
import { AuthResponse, AuthService, ProfileInfo } from '../services/auth.service';
import { ContextService } from '../services/context.service';
import { DataService } from '../services/data.service';
import { Menu } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { InsightService } from '../services/insight.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../services/common.service';
import { Notification } from 'src/app/shared/models/insight';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from '../services/domain.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public keyword: string = "";
  public context: Context = {} as Context;
  public session: AuthResponse = {} as AuthResponse;
  public companies: Company[] = [];
  public employees: Map<number, string>;
  public isCmpVisible: boolean = true;
  public isEmpVisible: boolean = true;
  public shortcutMenus: Menu[] = [];
  public profileInfo: ProfileInfo;
  private ctxSubscription: Subscription;
  private handleMultiEvent: boolean = false;
  private menus: Menu[] = [];
  public isLoading: boolean = true;
  public notifications: Notification[] = [];
  private notificationIds: number[] = [];
  private imageUrl: string;
  public image: string;
  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private authService: AuthService,
    private settingsService: SettingsService,
    private contextService: ContextService,
    private dataService: DataService,
    private insightService: InsightService,
    private commonService: CommonService,
    private toaster: ToastrService,
    private domainService:DomainService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.context = this.authService.getContextData();
    if (this.context.persona === 'EMPLOYEE' || this.context.persona === 'HR' || this.context.persona === 'ACCOUNTANT') {
      this.settingsService.getEmployeeProfileInfo().subscribe({
        next: profileInfo => this.profileInfo = profileInfo
      });
    } else if (this.context.persona === 'ADMIN' || this.context.persona === 'CLIENT') {
      this.settingsService.getAdminProfileInfo().subscribe({
        next: profileInfo => this.profileInfo = profileInfo
      });
    }
    this.dataService.companies().subscribe(companies => this.companies = companies);
    this.settingsService.getCompanies(false).subscribe((companies: Company[]) => {
      this.companies = companies.filter(company => !company.disabled);
    });
    // this.dataService.employees().subscribe(employees => this.employees = employees);
    this.dataService.menus().subscribe(menus => {
      this.menus = menus.reduce((acc, m) => acc.concat(m.submenu), []);
      this.shortcutMenus = this.getShortcutMenus(this.context?.persona);
    });
    this.checkDropDownVisibility();
    this.initData(this.context.persona);
    this.reInitContext();
    this.loadScripts();
    this.router.events.pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!this.handleMultiEvent) {
        this.handleMultiEvent = true;
        this.resetContext(event.url);
        setTimeout(() => {
          this.handleMultiEvent = false;
        }, 1000);
      }
    });
    this.getUnseenNotifications();
    this.domainService.companyImage$.subscribe((updatedImage) => {
      this.image = updatedImage;
      this.cdr.detectChanges(); 
    });
  
    if(this.context.employeeId && this.context.persona === 'EMPLOYEE' || this.context.persona === 'HR' || this.context.persona === 'ACCOUNTANT'){
      this.getImage();
    }
    if(this.context.companyId && this.context.persona === 'ADMIN' || this.context.persona === 'CLIENT'){
      this.getImageCompany();
    }
  }

  private reInitContext() {
    this.ctxSubscription = this.dataService.onContextReInit().subscribe(inited => {
      if (inited) {
        this.initData(this.context.persona);
        this.dataService.reInitContext(false);
      }
    });
  }

  private initData(persona: string) {
    switch (persona) {
      case 'ADMIN':
        this.getCompanies();
        break;
      case 'CLIENT':
        //Statement
        break;
      default:
        this.getEmployees();
    }
  }
  public resetContext(currentUrl: string) {
    currentUrl = currentUrl.split('?')[0];
    const excludedUrls: string[] = ['/appearance/leave', '/appearance/absence-list', '/appearance/regular', '/appraisal/application-list', '/payroll/renumaration-list'];
    const roles: string[] = ['HR'];
    if (excludedUrls.includes(currentUrl) && roles.includes(this.authService.getPersona())) {
      this.callContext();
    }
  }

  private callContext() {
    this.authService.callContext().subscribe({
      next: context => {
        this.authService.setContext(context);
        this.context = this.authService.getContextData();
      }
    });
  }

  private getCompanies() {
    this.settingsService.getCompanies(false).subscribe((companies: Company[]) => {
      this.companies = companies.filter(company => !company.disabled);
    });
  }

  private getEmployees() {
    this.contextService.getEmployees().subscribe((employees: Map<number, string>) => this.employees = employees);
  }

  public parseId(id: any) {
    return parseInt(id);
  }

  public setContext() {
    this.context.companyName = this.companies?.find(c => c?.id === this.context.companyId)?.name ?? 'Robocodex';
    this.context.employeeName = this.context?.employeeId ? this.employees[this.context.employeeId] : null;
    this.authService.setContext(this.context);
  }


  private loadScripts() {
    $(document).ready(function () {
      $('.mobile-search-icon').on('click', function () {
        $('.search-bar').addClass('full-search-bar');
      }),
        $('.search-close').on('click', function () {
          $('.search-bar').removeClass('full-search-bar');
        });
    });
  }

  private checkDropDownVisibility() {
    this.checkCompanyDropDownVisibility(this.router.url);
    this.checkEmployeeDropDownVisibility(this.router.url);
    this.router.events.pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event: any) => {
      this.checkCompanyDropDownVisibility(event.url);
      this.checkEmployeeDropDownVisibility(event.url);
    });
  }

  private checkCompanyDropDownVisibility(url: string) {
    const excludedUrls: string[] = ['/config/country-list', '/config/currency-list', '/config/timezone-list','/setup/social-media-list','/setup/insurance-list','/setup/company-list','/insight/reminder'];
    this.isCmpVisible = !excludedUrls.includes(url);
  }

  private checkEmployeeDropDownVisibility(url: string) {
    let excludedUrls: string[] = ['/setup/employee-list', '/setup/holiday-list', '/setup/title-list', '/setup/workday-list', '/appearance/absence-list', '/appearance/regular', '/appearance/leave', '/payroll/particular-list','/insight/reminder', "/payroll/providentFund-list", "/appraisal/appraisal-list", "/appraisal/application-list", "/appraisal/approval-list", "/appraisal/evaluation-list", "/setup/profile", "/appraisal/appraisal-cycle", "/appraisal/approval", "/appraisal/manage-approval", "/payroll/renumaration-list", "/appraisal/evaluation-report", "/finance/finance-report", "/finance/cashbook-list","/setup/metrics-list","/setup/performance-list"];
    if (this.context?.persona === 'ACCOUNTANT') {
      excludedUrls = [...excludedUrls, "/setup/social-media",
        "/insight/dashboard",
        "/finance/account-list",
        "/finance/journal-list",
        "/finance/ledger-list",
        "/setup/family-list",
        "/payroll/bank-list",
        "/payroll/orgchart",
        "/payroll/timeline"
      ];
    }
    if (this.context?.persona === 'EMPLOYEE') {
      excludedUrls = [...excludedUrls,
        "/payroll/bank-list",
      ];
    }
    if (this.context?.persona === 'HR') {
      excludedUrls = [...excludedUrls,
        "/insight/dashboard",
      ];
    }
    this.isEmpVisible = !excludedUrls.includes(url?.split('?')[0]);
  }

  public toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  public getSuggestions = (text$: Observable<string>) => text$.pipe(
    switchMap((term) => term == '' ? of([]) : of(this.menus.filter((a, b) => a.title.toLowerCase().startsWith(term.toLowerCase())))
    ),
  );

  public search(result?: Menu) {
    this.router.navigate([result.path]);
    this.keyword = "";
  }

  public formatter1(r: any) {
    return '';
  }

  private getShortcutMenus(role: string): Menu[] {
    let menus: Menu[] = this.menus;
    switch (role) {
      case 'ADMIN':
        const adminTitles: string[] = ['Dashboard', 'Company'];
        menus = menus.filter((m: Menu) => adminTitles?.includes(m?.title));
        break;
      case 'CLIENT':
        const clientTitles: string[] = ['Dashboard', 'Role', 'Employee'];
        menus = menus.filter((m: Menu) => clientTitles?.includes(m?.title));
        break;
      case 'HR':
        const hrTitles: string[] = ['Dashboard', 'Employee', 'Leave', 'Absence', 'Regularization', 'Vacay', 'Salary', 'Approval', 'Evaluation'];
        menus = menus.filter((m: Menu) => hrTitles?.includes(m?.title));
        break;
    }
    return menus;
  }

  public logout() {
    this.authService.signout();
  }

  private updateNotification(notificationIds: number[]) {
    this.insightService.updateNotification(notificationIds).subscribe({
      next: (message: Message) => {
        this.getUnseenNotifications();
        if (message.text) {
          this.commonService.setToastr(200, 'Notification marked as read!');
        } else {
          this.commonService.setToastr(500, message.text);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.commonService.setToastr(500, error?.error?.name || error.error);
      }
    });
  }
  private getUnseenNotifications() {
    this.insightService.getUnseenNotifications().subscribe({
      next: (notifications: Notification[]) => {
        // Sort the notifications by 'sentAt' (from newest to oldest)
        notifications.sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime());
        
        this.notifications = notifications;
        this.notificationIds = notifications.map(notification => notification.id);
        this.isLoading = false;
        // if (this.notificationIds) {
        //   this.updateNotification(this.notificationIds)
        // }
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.commonService.setToastr(500, error?.error?.name || error.error);
      },
    });
  }


  public clearNotifications() {
    this.notifications = [];
  }

  public markAllAsRead() {
    if (this.notificationIds.length > 0) {
      this.updateNotification(this.notificationIds);
    } 
  }

  ngOnDestroy(): void {
    this.ctxSubscription?.unsubscribe();
    this.authService.logout();
  }
  public markAsSeen(notificationId: number) {
    if (!this.notificationIds.includes(notificationId)) {
      return;
    }
    this.updateNotification([notificationId]);
    this.notifications = this.notifications.map(notification =>
      notification.id === notificationId ? { ...notification, seen: true } : notification
    );
    this.notificationIds = this.notificationIds.filter(id => id !== notificationId);
  }
  
  public getNotificationImage(type: string): string {
    switch (type) {
      case 'LEAVE':
        return 'assets/images/icons/leave.png';
      case 'ABSENCE':
        return 'assets/images/icons/abse.png';
      case 'REGULARIZATION':
        return 'assets/images/icons/regu.png';
      case 'INSURANCE':
        return 'assets/images/icons/insurance.png';
      default:
        return 'assets/images/avatar.png';
    }
  }

  public async getAndSetBase64(event: any) {
    const image: string = await this.commonService.convertImgToBase64(event);
    if (!image) return;
    this.imageUrl = image;
  }


  private saveImage(sid: number, refType: string, label: string, url: string) {
    let image: Image = {
      id: null,
      sid: sid,
      type: refType,
      label: label,
      image: url,
    };
    image.image = url
    this.domainService.createImage(image).subscribe({
      next: (response: string) => {
      },
      error: (error: HttpErrorResponse) => {
      }
    });
  }

  private getImage() {
    this.domainService.getImage('EMPLOYEE', 'EMPLOYEE', this.context.employeeId, 'avatar').subscribe(image => {
      this.image=image.payload;
      this.domainService.setEmployeeImage(image.payload);
    });
  }
  private getImageCompany() {
    this.domainService.getImage('COMPANY', 'COMPANY', this.context.companyId, 'logo').subscribe(image => {
      this.image=image.payload;
      this.domainService.setCompanyImage(image.payload);
    });
  }
}
